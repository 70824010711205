<template>
  <div>
    <div style="justify-content: flex-start">
      <img alt="more" class="title" v-if="handleDrillDownPlant == true" src="../../../assets/gov-up.svg"
        @click="handleDrillDown('region', activeTab)" />
    </div>
    <div id="region-name" class="primary-color d-flex">
      <div v-for="region in regionList" :key="region" class="title">
        {{ region }}
      </div>
    </div>
    <div style="background: none" id="plant-name" class="menu-body primary-color d-flex"
      v-if="handleDrillDownPlant == true">
      <div v-for="plant in plantList" :key="plant" class="title">
        {{ plant }}
      </div>
    </div>

    <canvas id="plant-chart" :width="40" :height="9" style="background-color: white"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import plantChartData from "@/views/Reports/GapClosure/data/plant-data";
import colorCodes from "./constants/colorCodes.js";

export default {
  name: "PlantChart",
  data() {
    return {
      plantChartData: plantChartData
    };
  },
  props: {
    plantGapClosureData: {},
    regionList: [],
    plantList: [],
    activeTab: String,
    handleDrillDown: Function,
    handleDrillDownPlant: Boolean
  },
  mounted() {
    let statusColor = [];
    this.plantGapClosureData.status.forEach((status, key) => {
      if (status === 'offtrack' && this.plantGapClosureData.aop[key] <= this.plantGapClosureData.completed[key]) {
        statusColor.push(colorCodes['ontrack']);
      } else {
        statusColor.push(colorCodes[status]);
      }
    });
    this.plantChartData.data.labels = this.plantGapClosureData.label;
    this.plantChartData.data.datasets[0].data = this.plantGapClosureData.aop && this.plantGapClosureData.aop.map((i, pos) => (i ? i : null));
    this.plantChartData.data.datasets[1].data = this.plantGapClosureData.completed.map((i, pos) => (i ? i : 0));

    this.plantChartData.data.datasets[1].backgroundColor = statusColor;
    this.plantChartData.data.datasets[1].borderColor = statusColor;

    if (this.plantGapClosureData.label.length > 30) {
      this.plantChartData.data.datasets[0].datalabels.labels.value.font.size = window.innerWidth > 1500 ? 11 : 9;

      this.plantChartData.data.datasets[1].datalabels.labels.value.font.size = window.innerWidth > 1500 ? 14 : 9;
      this.plantChartData.data.datasets[1].barPercentage = 1;

    } else {
      this.plantChartData.data.datasets[0].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 9 : 11;

      this.plantChartData.data.datasets[1].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
      this.plantChartData.data.datasets[1].barPercentage = 0.5;
    }

    this.plantChartData.options.plugins.datalabels.formatter = (val, context) => {
      if (val === 0 || !val) return "";
      if (val) return typeof val === "number" ? val : val[0] - val[1];
    };

    const ctx = document.getElementById("plant-chart");
    Chart.register(ChartJsPluginDataLabels);
    let chart = new Chart(ctx, this.plantChartData);
    console.log(this.plantChartData);
    console.log(chart)
  }
};
</script>
<style scoped>
.blank {
  display: none;
}

.d-flex {
  display: flex;
  justify-content: space-around;
}

.title {
  font: 1.33rem "BarlowR", sans-serif;
}
</style>
