<template>
  <div>
    <canvas id="global-chart" :width="40" :height="10" style="background-color: white"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import plantChartData from "@/views/Reports/GapClosure/data/plant-data";
import colorCodes from "./constants/colorCodes.js";

export default {
  name: "GlobalChart",
  data() {
    return {
      plantChartData: plantChartData
    };
  },
  props: {
    globalGapClosureData: {}
  },
  mounted() {
    console.log("Global", this.globalGapClosureData);
    let statusColor = [];
    this.globalGapClosureData.status.forEach((status, key) => {
      if (status === 'offtrack' && this.globalGapClosureData.aop[key] < this.globalGapClosureData.completed[key]) {
        statusColor.push(colorCodes['ontrack']);
      } else {
        statusColor.push(colorCodes[status]);
      }
    });

    this.plantChartData.data.labels = this.globalGapClosureData.label;
    
    this.plantChartData.data.datasets[0].data = this.globalGapClosureData.aop && this.globalGapClosureData.aop.map((i, pos) => (i ? i : null));
    this.plantChartData.data.datasets[1].data = this.globalGapClosureData.completed.map((i, pos) => (i ? i : 0));

    this.plantChartData.data.datasets[1].backgroundColor = statusColor;
    this.plantChartData.data.datasets[1].borderColor = statusColor;



    if (this.globalGapClosureData.label.length > 14) {
      this.plantChartData.data.datasets[0].datalabels.labels.value.font.size = 9;

      this.plantChartData.data.datasets[1].datalabels.labels.value.font.size = 9;
      this.plantChartData.data.datasets[1].barPercentage = 1;
    } else {
      this.plantChartData.data.datasets[0].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 9 : 11;

      this.plantChartData.data.datasets[1].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
      this.plantChartData.data.datasets[1].barPercentage = 0.5;
    }

    this.plantChartData.options.plugins.datalabels.formatter = (val, context) => {
      if (val === 0 || !val) return "";
      if (val) return typeof val === "number" ? val : val[0] - val[1];
    };


    const ctx = document.getElementById("global-chart");
    Chart.register(ChartJsPluginDataLabels);
    let chart = new Chart(ctx, this.plantChartData);
    console.log('GLOBAL: plantChartData', this.plantChartData);
    console.log(chart)
  }
};
</script>
<style scoped>
.blank {
  display: none;
}
</style>
