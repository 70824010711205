const Completed = "#696969";
const Committed = "#B4B4B4";
const ontrack = "#3a5824";
// const ontrack = "#24693d"
const offtrack = "#c11a3b";
// const offtrack = "#f3722c";
const Prior = "#2b5784";
// OEE Report
const TotalTime = "#014990";
const Loss = "#FF3636";
const STValue = "#EE90D2";
const ATValue = "#FFA52C";
const OTValue = "#109B10";
const EFValue = "#F4F88C";

export default {
  Completed,
  Committed,
  ontrack,
  offtrack,
  Prior,
  TotalTime,
  Loss,
  STValue,
  ATValue,
  OTValue,
  EFValue
};
