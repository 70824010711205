<template ref="PDFMake"></template>

<script>
import SpiderChartReport from './SpiderChartReport.vue';

export default {
  name: "PDFMake",
  data() {
    return {
      download: false,
    };
  },
  created() {
    if (this.download) {
      this.pdfmake("");
    }
  },
  mixins: [SpiderChartReport],
  methods: {
    pdfmake: async function (type, filename = "Chart") {
      let pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        let pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let docDefinition = {
        pageSize: "A4",
        pageMargins: [20, 65, 20, 35],
        pageOrientation: "landscape",
        content: this.exportGraphToPDF("landscape", type),
      };

      docDefinition.header = await this.addPdfMakeHeader(filename);
      docDefinition.footer = await this.addPdfMakeFooter();

      pdfMake.createPdf(docDefinition).download(filename + ".pdf");
    },
    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
      let ratio = srcWidth / srcHeight;
      let width = maxWidth;
      let height = maxHeight;
      if (srcWidth <= maxWidth && srcHeight <= maxHeight) {
        // source less that maximum size print as it is
        width = srcWidth;
        height = srcHeight;
      } else if (srcWidth >= srcHeight) {
        //width is larger
        height = maxWidth / ratio;
        if (height > maxHeight) {
          //calcualted height greater than maxheight recalucate by adjusting height
          height = maxHeight;
          width = maxWidth * ratio;
          if (width > maxWidth) {
            width = maxWidth;
          }
        }
      } else {
        width = maxHeight * ratio;
        if (width > maxWidth) {
          //calcualted width greater than maxWidth recalucate by adjusting width
          height = maxWidth / ratio;
          width = maxWidth;
        }
      }
      return { width: width, height: height };
    },
    exportGraphToPDF: function (orientation, type) {
      let canvasElm = document.getElementById(type);
      let docDefinition = {
        content: [],
      };
      if (type !== "gc-table") {
        docDefinition.content = this.convertCanvas(orientation, canvasElm);
        return docDefinition.content;
      }
    },
    convertCanvas(orientation, canvasElm) {
      let labelHeader = "";
      let docDefinition = {
        content: [],
      };
      if (canvasElm) {
        let img = new Image();
        img.src = canvasElm.toDataURL();
        let imgData = canvasElm.toDataURL();
        let resolution = this.calculateAspectRatioFit(
          canvasElm.clientWidth,
          canvasElm.clientHeight,
          550,
          800
        );
        let width, height;
        if (
          (resolution.width > 555 && orientation === "portrait") ||
          (resolution.width < 812 && orientation === "landscape")
        ) {
          width = orientation === "portrait" ? 555 : 812;
        } else {
          width = resolution.width;
        }

        if (resolution.height < 200) {
          height = 200;
        } else {
          height = resolution.height;
        }

        docDefinition.content.push({
          image: imgData,
          width: width,
          height: height,
          positions: [
            {
              horizontalRatio: 0,
              left: 20,
              pageInnerHeight: 450.28,
              pageInnerWidth: 801.89,
              pageNumber: 1,
              pageOrientation: "landscape",
              top: 70,
              verticalRatio: 0.33312605489917385,
            },
          ],
          x: 0,
          y: 240,
          alignment: "centre",
          margin: [0, 0, 0, 0],
        });

        docDefinition.content.push({
          text: labelHeader,
          style: {
            fontSize: this.fontSize,
          },
        });
      }
      return docDefinition.content;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>