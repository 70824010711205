<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="outer-block">
            <div class="button-block">
                <div class="button-dd">
                    <div title="Download All" class="download-view action-btn" @click="downloadPdf">
                        <img alt="download" src="../../../assets/download_icon.svg" />
                    </div>
                </div>
                <div class="button-dd" v-if="$oidc.userProfile.roles.includes(roles.GLOBALPILLARLEADER)">
                    <div title="Email All Graphs" class="download-view action-btn" @click="emailPdf">
                        <img alt="download" src="../../../assets/email.svg" />
                    </div>
                </div>
            </div>
            <div class="plantGap" :id='"plant-wise-block-" + index' v-for="(plantData, index) in plantDataBackup"
                :key="index">
                <div class="primary-color title-text center-aligned">{{ new Date().getFullYear() }}
                    {{ plantData.plantName }}{{ plantData.region }}</div>

                <canvas :id="'plant-wise-chart-' + index" :width="40" :height="10" style="background-color: white"></canvas>

                <div class="sample-list">
                    <div class="prior-years-actual"></div>
                    <span class="sample-span">Prior Years Actual</span>
                    <div class="committed"></div>
                    <span class="sample-span">Forecast</span>
                    <div class="aop-values"></div>
                    <span class="sample-span">AOP Target</span>
                    <div class="on-track"></div>
                    <span class="sample-span">Completed</span>
                    <div class="off-track"></div>
                    <span class="sample-span">Off Track</span>
                    <div class="not-sustained"></div>
                    <span class="sample-span">Not Sustained</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GlobalChart from "./GlobalChart.vue";
import PlantChart from "./PlantChart.vue";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import XLSX from "xlsx";
import Api from "@/common/constants/api";
import ApiCalls from "@/common/api/apiCalls";
import ActionItemFilter from "@/components/Dashboard/ActionItemFilter.vue";
import PDFMake from "@/common/helper/PDFMake.vue";
import Helper from "@/common/helper/";
import Printd from "printd";
import colorCodes from "@/common/constants/colorCodes";
import html2canvas from "html2canvas";
import SpiderChartReport from "@/common/helper/SpiderChartReport.vue";
import Roles from "@/common/constants/roles";
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import plantChartData from "./data/each-plant-data";

export default {
    name: "GapEachPlant",
    data() {
        return {
            obj: new Printd(),
            showLoader: false,
            roles: Roles,
            printData: [],
            plantDataBackup: [],
        };
    },
    components: {
        Breadcrumb,
        Loader,
        GlobalChart,
        PlantChart,
        ApiCalls,
        Api,
        ActionItemFilter,
        // JsonExcel,
        XLSX
    },
    mixins: [PDFMake, SpiderChartReport],
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 3,
            subMenu: 9
        });
        this.$store.dispatch("breadcrumb", {
            title: "Gap Closure Monthly Report",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Reports / ", primary: true, redirect: "/" },
                { name: "Gap Closure Monthly Report", primary: false }
            ]
        });
    },
    created() {
        this.showLoader = true;
        this.getPlantGapClosureData();
    },
    methods: {
        downloadPdf() {
            this.download = true;
            this.showLoader = true;
            this.pdfMakeCall("plantWiseGapClosure", this.printData);
            setTimeout(() => {
                this.showLoader = false;
            }, 120 * 1000);
        },
        emailPdf() {
            this.download = true;
            this.showLoader = true;
            this.pdfMakeAndEmail("plantWiseGapClosure", this.printData);
            setTimeout(() => {
                this.showLoader = false;
            }, 120 * 1000);
        },
        getPlantGapClosureData() {
            this.plantDataBackup = [];
            ApiCalls.httpCallDetails(Api.GETMASTERREPORTDATA + "?Year=" + new Date().getFullYear(), "get").then((data) => {
                data.data.globalReport['plantName'] = data.data.globalReport.type
                data.data.globalReport['region'] = ''
                this.plantDataBackup.push(data.data.globalReport)
                data.data.getGapClosureReportPlantTypeList.sort((a, b) => {
                    if (a.type < b.type) return -1
                    return (a.type === b.type) ? 0 : 1
                })
                data.data.getGapClosureReportPlantTypeList.forEach(regional => {
                    regional['plantName'] = regional.type
                    regional['region'] = ''
                })
                this.plantDataBackup = [...this.plantDataBackup, ...data.data.getGapClosureReportPlantTypeList]
                data.data.allPlantsReport.plantData.forEach(plants => {
                    plants['region'] = ', ' + plants['region']
                })
                this.plantDataBackup = [...this.plantDataBackup, ...data.data.allPlantsReport.plantData]
                console.log(this.plantDataBackup)
                setTimeout(() => {
                    this.plantGraph();
                }, 500);
            });
        },
        plantGraph() {
            this.printData = [];
            this.plantDataBackup.forEach((plantData, pos) => {
                this.printData.push("plant-wise-block-" + pos);

                const individualPlantChartData = JSON.parse(JSON.stringify(plantChartData));

                individualPlantChartData.data.labels = plantData.label;
                individualPlantChartData.data.datasets[0].data = plantData.committed;
                individualPlantChartData.data.datasets[1].data = plantData.aop && plantData.aop.map((i, pos) => (i ? i : null));
                individualPlantChartData.data.datasets[2].data = plantData.completed.map((i, pos) => (i ? i - plantData.notSustained[pos] : 0));
                individualPlantChartData.data.datasets[3].data = plantData.notSustained && plantData.notSustained.map((i, pos) => (i ? i : null));
                individualPlantChartData.data.datasets[4].data = plantData.offtrack && plantData.offtrack.map((i, pos) => (i ? i : null));
                individualPlantChartData.data.datasets[5].data = plantData.notSustained.map((_i, pos) => ((pos === (new Date().getMonth() + 1)) ? Number(plantData.completed[pos]) : null));

                individualPlantChartData.data.datasets[6].data = plantData.notSustained.map((_i, pos) => ((pos === (new Date().getMonth() + 1)) && plantData.aop && plantData.aop.length && Number(plantData.aop[pos]) && Number(plantData.aop[pos]) - Number(plantData.completed[pos]) >= 0 ? Number(plantData.aop[pos]) - Number(plantData.completed[pos]) : null));


                individualPlantChartData.data.datasets[2].backgroundColor = this.colorSelector(plantData.status);
                individualPlantChartData.data.datasets[2].borderColor = this.colorSelector(plantData.status);


                // individualPlantChartData.data.datasets[5].backgroundColor = individualPlantChartData.data.datasets[5].borderColor = individualPlantChartData.data.datasets[5].data.map((total, pos) => plantData.aop && plantData.aop[pos] > total ? '#c11a3b' : '#189D99');
                individualPlantChartData.data.datasets[6].borderColor = individualPlantChartData.data.datasets[5].data.map((total, pos) => plantData.aop && plantData.aop[pos] > total ? '#c11a3b' : '#189D99');


                if (plantData.label.length > 14) {
                    individualPlantChartData.data.datasets[0].datalabels.labels.value.font.size = 9;
                    individualPlantChartData.data.datasets[1].datalabels.labels.value.font.size = 12;

                    individualPlantChartData.data.datasets[2].datalabels.labels.value.font.size = 9;
                    individualPlantChartData.data.datasets[2].barPercentage = 1;

                    individualPlantChartData.data.datasets[3].datalabels.labels.value.font.size = 9;
                    individualPlantChartData.data.datasets[3].barPercentage = 1;

                    // individualPlantChartData.data.datasets[5].datalabels.labels.value.font.size = 9;
                    // individualPlantChartData.data.datasets[5].barPercentage = 1;
                } else {
                    individualPlantChartData.data.datasets[0].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 9 : 11;
                    individualPlantChartData.data.datasets[1].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 12 : 15;

                    individualPlantChartData.data.datasets[2].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                    individualPlantChartData.data.datasets[2].barPercentage = 0.5;

                    individualPlantChartData.data.datasets[3].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                    individualPlantChartData.data.datasets[3].barPercentage = 0.5;

                    individualPlantChartData.data.datasets[4].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                    individualPlantChartData.data.datasets[4].barPercentage = 0.5;

                    // individualPlantChartData.data.datasets[5].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                    // individualPlantChartData.data.datasets[5].barPercentage = 0.5;

                    individualPlantChartData.data.datasets[6].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
                    individualPlantChartData.data.datasets[6].barPercentage = 0.5;
                }

                individualPlantChartData.options.plugins.datalabels.formatter = (val, context) => {
                    if (val === 0 || !val) return "";
                    return typeof val === "number" ? val : val[0] - val[1];
                };

                //options for stacked bar graph
                // individualPlantChartData.options.scales.x = { stacked: true };
                // individualPlantChartData.options.scales.y = { stacked: true };

                individualPlantChartData.data.datasets[2].borderRadius = plantData.notSustained.map((count, pos) => {
                    return count || individualPlantChartData.data.datasets[4].data[pos] ? { bottomLeft: 100, bottomRight: 100, topLeft: 0, topRight: 0 } : 100;
                });

                individualPlantChartData.data.datasets[3].borderRadius = plantData.notSustained.map((count, pos) => {
                    return count && individualPlantChartData.data.datasets[4].data[pos] ? { bottomLeft: 0, bottomRight: 0, topLeft: 0, topRight: 0 }
                        : { bottomLeft: 0, bottomRight: 0, topLeft: 100, topRight: 100 };
                });

                individualPlantChartData.data.datasets[4].borderRadius = plantData.offtrack.map((count, pos) => {
                    return count ? { bottomLeft: 0, bottomRight: 0, topLeft: 100, topRight: 100 } : 100;
                });
                Chart.register(ChartJsPluginDataLabels);
                const ctx = document.getElementById("plant-wise-chart-" + pos);
                const obj = new Chart(ctx, individualPlantChartData);
                obj.draw();
            });
            setTimeout(() => {
                this.showLoader = false;
            }, 500);
        },
        colorSelector(statuses) {
            return statuses.map((status) => {
                if (status === "ontrack") return colorCodes.ontrack;
                else if (status === "offtrack") return colorCodes.offtrack;
                else return colorCodes.Prior;
            });
        }
    }
};
</script>
<style scoped>
#chart {
    padding: 0 2rem 2rem 0;
}

.plantGap {
    padding-bottom: 2rem;
}

.outer-block {
    background-color: #fff;
    border-radius: 6px;
    padding: 2rem;
}

.tab-titles {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-options {
    display: flex;
    margin-top: 2rem;
}

.tab-option-text {
    font: 1.16rem "BarlowR", sans-serif;
    cursor: pointer;
}

.in-action {
    margin-right: 1.688em;
}

.tab-active-option {
    font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
    border-top: 3px #274ba7 solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    margin-top: 1rem;
}

.select-dd {
    width: 10%;
}

.button-dd {
    display: inline-flex;
    float: right;
}

.button-block {
    width: 100%;
    height: 3rem;
}

.funnel-con {
    /* border-radius: 2rem;
      border: 1px solid #274ba7;
      margin-left: 1rem;
      font-size: 1.33rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem; */
    font-size: 1.33rem;
}

.sample-span {
    margin-right: 1rem;
    font: 1.08rem "BarlowM", sans-serif;
    color: #313131;
}

.sample-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

.title-text {
    font: 1.33rem "BarlowSb", sans-serif;
    padding: 0 0 2rem 0;
}

.data-table tbody {
    font-size: 0.875em;
}

#reports-gc-data-table th div {
    color: #313131;
}

#reports-gc-data-table tbody tr:nth-child(odd) {
    background-color: #fafafa !important;
}

#reports-gc-data-table tbody tr:nth-child(even):hover {
    background-color: #fff !important;
}

#reports-gc-data-table tr td:first-child {
    padding-left: 1.25em !important;
}

#reports-gc-data-table tr th:first-child {
    padding-left: 1.25em !important;
}

.download-view {
    border-radius: 2rem;
    border: 1px solid #274ba7;
    margin-left: 1rem;
    font-size: 1.33rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.download-view:hover {
    background: #e3e8f7;
}

.action-btn {
    border: 1px #274ba7 solid;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    cursor: pointer;
}

.hr {
    position: relative;
    top: -26px;
}

.center-aligned {
    text-align: center;
    padding: 1rem 0 !important;
}

#data-block {
    padding: 0 2rem;
}

#data-block>div.data-table.gn-table.table-responsive {
    height: 40rem;
}
</style>