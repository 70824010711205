import colorCodes from "@/common/constants/colorCodes";

export const plantChartData = {
  type: "bar",

  data: {
    labels: [],
    datasets: [
      {
        label: "Committed Maturity Assessment Practices Line",
        type: "line",
        borderDash: [3, 5],
        yAxisID: "y",
        animation: true,
        data: [],
        datalabels: {
          align: "center",
          anchor: "center",
          color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#fff",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#333"
            }
          }
        },
        backgroundColor: colorCodes.Committed,
        borderColor: colorCodes.Committed
      },
      {
        label: "AOP Target Line",
        type: "line",
        borderDash: [3, 5],
        animation: true,
        // yAxisID: "y1",
        data: [],
        datalabels: {
          align: "end",
          anchor: "center",
          color: "#e32424",
          labels: {
            value: {
              textStrokeColor: "#fff",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 14
              },
              color: "#e32424"
            }
          }
        },
        backgroundColor: "#e32424",
        borderColor: "#e32424"
      },
      {
        label: "Completed Maturity Assessment Practices",
        type: "bar",
        yAxisID: "y",
        stack: "Stack 0",
        borderRadius: 100,
        borderSkipped: false,
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          labels: {
            value: {
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: [],
        borderColor: ["#fff"],
        borderWidth: 1,
        barPercentage: 0.25
      },
      {
        label: "Not Sustained Maturity Assessment Practices",
        type: "bar",
        yAxisID: "y",
        borderRadius: 100,
        stack: "Stack 0",
        borderSkipped: false,
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          // color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#444",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: "#ffc400",
        borderColor: "#ffc400",
        borderWidth: 1,
        barPercentage: 0.25
      },
      {
        label: "Off Track Maturity Assessment Practices",
        type: "bar",
        borderRadius: 100,
        yAxisID: "y",
        stack: "Stack 0",
        borderSkipped: false,
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          // color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#444",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: "#f3722c",
        borderColor: "#f3722c",
        borderWidth: 1,
        barPercentage: 0.25
      },
      {
        label: "Total Maturity Assessment Practices",
        type: "bar",
        borderRadius: { bottomLeft: 100, bottomRight: 100, topLeft: 0, topRight: 0 },
        yAxisID: "y",
        borderSkipped: false,
        stack: "Stack 1",
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          // color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#444",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: "#189D99",
        borderColor: "#137C78",
        borderWidth: 1,
        barPercentage: 0.25
      },
      {
        label: "No. of Difference",
        type: "bar",
        borderRadius: { bottomLeft: 0, bottomRight: 0, topLeft: 100, topRight: 100 },
        yAxisID: "y",
        borderSkipped: false,
        stack: "Stack 1",
        data: [],
        datalabels: {
          rotation: "270",
          align: "center",
          anchor: "center",
          clamp: true,
          // color: "#bbb",
          labels: {
            value: {
              textStrokeColor: "#444",
              textStrokeWidth: 3,
              font: {
                weight: 800,
                family: "BarlowB",
                size: 10
              },
              color: "#fff"
            }
          }
        },
        backgroundColor: "#00000000",
        borderColor: "#137C78",
        borderWidth: 1,
        barPercentage: 0.5
      }
    ]
  },
  options: {
    responsive: true,
    lineTension: 0,
    interaction: {
      intersect: false
    },
    elements: {
      bar: {
        // hoverBackgroundColor:'yellow'
      }
    },
    scales: {
      y: {
        // stacked: true,
        display: true,
        title: {
          display: true,
          text: "Completed Maturity Assessment Practices",
          padding: { top: 30, left: 0, right: 0, bottom: 0 },
          fontStyle: "bold",
          fontSize: 16,
          fontColor: "#333"
        }
      },
      // y1: {
      //   suggestedMax: 100,
      //   display: true,
      //   position: "right",
      //   title: {
      //     display: true,
      //     text: "AOP Targets",
      //     padding: { top: 30, left: 0, right: 0, bottom: 0 },
      //     fontStyle: "bold",
      //     fontSize: 16,
      //     fontColor: "#333"
      //   },
      //   // grid line settings
      //   grid: {
      //     drawOnChartArea: false // only want the grid lines for one axis to show up
      //   }
      // },
      x: {
        stacked: true
      }
    },
    plugins: {
      title: {
        display: true,
        text: ""
      },
      legend: {
        display: false
      },
      datalabels: {
        formatter: (val) => {
          console.log(val);
          if (val) return `${val.toLocaleString()}`;
          return ``;
        }
        // labels: {
        //   value: {
        //     color: "#004ea8",
        //   },
        // },
      }
    }
  }
};

export default plantChartData;
