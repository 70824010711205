<template ref="my-modal">
  <div>
    <div class="filter">
      <div class="filter-header-font-style primary-color filter-name">
        {{ filterHeading? filterHeading: "Filters" }}
      </div>
      <div class="act-con">
        <div class="close" @click="hideModal">
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </div>
        <div>
          <b-button pill class="page-heading-dropdown-content aplly-btn mr-10" @click="applyFilter">Apply</b-button>
        </div>
        <div class="page-heading-dropdown-content primary-color mr-10" @click="resetFilter">Reset</div>
      </div>
    </div>
    <hr class="dividera" />
    <div class="filter-con">
      <div class="mb-3 fc-con" v-for="(item, index) in filterArray" v-bind:key="item.id">
        <label :for="item.id" class="form-label filter-content-font-style lable-text">{{ item.labelName }}</label>
        <select v-if="!item.multiple" class="form-control font-style-select filter-header-font-style cust-ft-icon"
          :id="item.id" v-model="selection[index]" @change="changedFilter">
          <option :value="item.id">Select {{ item.id }}</option>
          <option v-for="arrayValue in item.arrayValue" :key="arrayValue" :value="arrayValue">
            {{ arrayValue }}
          </option>
        </select>
        <div id="id_multi" v-if="item.multiple && item.multiple === true"
          :class="{ 'height-increase': item.arrayValue.length >= 10 }" style="height: 15rem">
          <Multiselect :id="item.id" class="form-control font-style-select filter-header-font-style"
            :placeholder="'Select ' + item.labelName" v-model="dataArray[item.id]" :options="item.arrayValue"
            label="label" track-by="value" :multiple="true" :close-on-select="false" :clear-on-select="false"
            :preserve-search="false" :preselect-first="false" @select="onSelect($event, index)"
            @remove="onRemove($event, index)">
            <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
              <label style="margin-left: 1rem">
                <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent />
                {{ scope.option.label }}
              </label>
            </span>
          </Multiselect>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "ActionItemFilter",
  props: {
    hideModal: Function,
    filterArray: [],
    applyFilter: Function,
    resetFilter: Function,
    changedFilter: Function,
    filterSelected: [],
    onSelect: Function,
    onRemove: Function,
    filterHeading: String
  },
  components: {
    Multiselect
  },
  data() {
    return {
      selection: [],
      dataArray: {
        Year: [],
        Region: [],
        PBU: [],
        PlantName: [],
        Pillar: [],
        PrincipleNumber: [],
        Level: [],
        Status: [],
        Type: []
      }
    };
  },
  mounted() {
    this.selection = [];
    this.filterSelected.forEach((val) => {
      if (val.arrayValue.length === 1 && !val.multiple) this.selection.push(val.arrayValue[0]);
      else if (val.arrayValue.length > 1 && !val.multiple) this.selection.push(val.id);
    });
    this.dataArray = {
      Year: [],
      Region: [],
      PBU: [],
      PlantName: [],
      Pillar: []
    };
  },
  method: {
    showModal() {      
    }
  }
};
</script>
<style scoped>
.cust-ft-icon {
  background: url(../../assets/Arrow_Dropdown.svg) no-repeat 98% center;
  -webkit-appearance: none;
  padding-right: 2rem;
}

.cust-ft-icon:disabled {
  background: url(../../assets/Drop-down_disabled.svg) no-repeat 98% center;
  -webkit-appearance: none;
  padding-right: 2rem;
}

.font-style-select {
  font-size: 1.3333333333333333rem;
  font-family: "BarlowR", sans-serif;
}

.checkbox-label {
  display: inline-block;
}

.test {
  position: relative;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.filter {
  display: flex;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.filter-name {
  font-weight: 600;
  width: 50%;
}

.act-con {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
  align-items: center;
  margin-top: -6px;
}

.close {
  color: "#E6E6E6";
}

.aplly-btn {
  background: #274ba7;
  border-color: #274ba7;
  width: 5em;
}

.filter-con {
  padding-left: 2rem;
  padding-right: 2rem;
}

.filter-con .form-control {
  border: 1px solid #313131;
  height: 2.8em;
  color: #274ba7;
  font-weight: 600;
}

.form-label {
  color: #274ba7;
}

.filter-con .form-control:focus {
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

.lable-text {
  position: absolute;
  background: #fff;
  margin: -0.5rem 0 0 0.7rem;
  padding-right: 5px;
  padding-left: 5px;
}

.fc-con {
  margin-top: 1.5rem;
}

.dividera {
  margin-top: 0rem !important;
}

/* select {
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 244px;
} */
</style>
